
<template>
  <div class="padding-fixed">
    <div class="common-bg padding-50" style="padding-top: 20px">
      <div class="container">

        <!--  loading bg      -->
        <div class="f5-bg" v-if="loadingBg"></div>
        <!--  loading bg      -->

        <div class="news-return" @click="returnClick" style="cursor: pointer">
          <i class="layui-icon layui-icon-left"></i> 返回
        </div>
        <div class="news-content" style="padding-top: 20px">
          <h1>{{ title }}</h1>
          <img :src="image" class="cover-img img-center">
          <div class="padding-top-50">
            <div class="news-editor serveMore-item" v-for="item in intro" :key="item">
              <p><b>{{item.title}}</b></p>
              <p style="white-space: pre-wrap;">{{item.info}}</p>
            </div>
          </div>

        </div>


        <!--   這裡要判斷 有內容才顯示,沒有不顯示      -->
        <div v-if="quarterly.length > 0">


        <h1 class="review-title"><b>中心季刊圖</b></h1>
        <div class="quarterly">
          <a :href="item.pdf" v-for="item in quarterly" :key="item" target="_blank">
            <img :src="item.image">
          </a>
        </div>

        </div>


        <h1 class="review-title"><b>活動回顧</b></h1>

        <div class="review-add-part">
          <div class="review-add-item" v-for="item in review" :key="item">
            <a :href="item.link" target="_blank">
              <img :src="item.image">
              <p>{{ item.title }}</p>
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import imgSwiper from "@/views/serviceDetail/imgSwiper";
import {getDetail, getContactInfo} from "@/network/home";

export default {
  name: "serviceMore",
  components: {
    // imgSwiper
  },
  data() {
    return {
      id: null,
      title: {},
      image: {},
      intro: [],
      review: [],
      quarterly:[],
      loadingBg: false,
    }
  },
  created() {
    this.loadingBg = true;
    this.getContactInfo();

    this.id = this.$route.params.id; //拿到id
    getDetail(this.id).then(res => {
      console.log(res)
      this.title = res.data.data.title;
      this.image = res.data.data.image;
      this.intro = res.data.data.intro;
      this.review = res.data.data.review;
      this.quarterly = res.data.data.center_quarterly_chart;
      console.log(res.data.data.review)
      // console.log(this.review.length)
      this.loadingBg = false;
    });


  },
  methods:{
    //拿到img显示个数
    getContactInfo() {
      getContactInfo().then(res => {
        this.page_set = res.data.data.setting.page_set;
        // this.page_set = parseInt(this.page_set);
        //
        // console.log(res)
        // console.log(typeof (this.page_set))

      })
    },
    buttonClick() {
      this.$router.push('/review/' + this.id)//传递id,给查看更多调用接口
    },
    returnClick() {
      this.$router.go(-1)
    },
  },

}
</script>

<style scoped>
.f5-bg{
  width: 100%;
  height: 800px;
  background: #F5F5F6;
}

.padding-fixed{
  padding-top: 92px;
}
.news-return{
  display: flex;
  justify-content: flex-end;
  color: #F76F01;
}
.news-content h1{
  text-align: center;
  color: #F70132;
  padding: 0;
  font-size: 38px;
}

img.cover-img{
  padding: 30px 0;
  width: 60%;
}
.news-editor p{
  padding: 5px 0;
}
.news-editor img{
  padding: 10px 0;
  max-width: 100%!important;
  width: inherit!important;
  height: inherit!important;
  display: block;
  margin: auto;
}

h1{
  padding:30px 0;
  font-weight: bold;
}
.ac-btn{
  display: flex;
  align-content: center;
  justify-content: center;
}
.ac-btn span{
  padding: 10px 30px;
  font-size: 16px;
  display: inline-block;
  border: 1px solid #F76F01;
  border-radius: 8px;
  background: #fff;
  margin-top: 50px;
  cursor: pointer;
}
.serveMore-item{
  margin-top: 10px;
  font-size: 17px;
}

.review-add-part{
  display: flex;
  flex-wrap: wrap;
}

.review-add-item {
  width: 33.33%;
  padding: 10px 20px;
}

.review-add-item P{
  font-size: 18px;
  color: #000;
  padding-top: 10px;
}

.quarterly img{
  width: 33%;
  padding: 10px;
}


@media only screen and (min-width:0px) and (max-width:991px) {
  .padding-fixed{
    padding-top: 68px;
  }

  .ac-btn span{
    margin: 20px 0;
  }

  h1{
    padding: 0;
    font-size: 24px;
  }

  img.cover-img{
    padding: 10px 0 0 0;
  }

  .serveMore-item{
    margin-top: 0;
  }

  h1.review-title{ margin: 20px 0;
    font-size: 38px;
  }

  .review-add-item {
    width:100%;

  }

  .news-content h1, h1.review-title{
     font-size:24px;
  }


  img.cover-img{
    width: 100%;
  }

}


</style>